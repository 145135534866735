
import Vue from 'vue';
import Map from '../components/Map.vue';
import MeasurementCard from '../components/MeasurementCard.vue';
import { db } from '@/db';


export default Vue.extend({
  name: 'Measurements',

  data: () => ({
    locations: [] as Array<firebase.firestore.CollectionReference<firebase.firestore.DocumentData>>,
    mapCenter: [-18.211587827629742, 64.9143587065569],
    mapZoom: 5.55,
    measurementData: [] as firebase.firestore.DocumentData[],
    zoomLevelOnClick: 15,
    measurementIds: [] as string[],
    selectedLocationName: '',
    renderMesurement: false,
    renderNoDataComponent: false,
    markerClicked: false,
    loading: false,
    extraLocations: [] as any[],
  }),

  components: {
    Map,
    MeasurementCard,
  },

  methods: {
    async renderMeasurement(location: any) {
      this.markerClicked = true;
      this.loading = true;
      const locationId = location.id;
      const snapshot = await db.collection('masw_measurements')
        .where('location_id', '==', locationId)
        .get();

      this.loading = false;

      if (snapshot.empty) {
        this.selectedLocationName = '';
        this.measurementData = [];
        this.measurementIds = [];
        this.renderMesurement = false;
        this.renderNoDataComponent = true;
        this.extraLocations = [];
      } else {
        this.measurementData = snapshot.docs.map((_) => _.data());
        this.measurementIds = snapshot.docs.map((_) => _.id);
        this.selectedLocationName = location.name;
        this.updateExtraLocations();
        this.mapCenter = [location.location.longitude, location.location.latitude];
        if (this.extraLocations.length > 0) {
          // We want to zoom in the map to show the exact location
          this.mapZoom = this.zoomLevelOnClick;
        }
        this.renderMesurement = true;
        this.renderNoDataComponent = false;
      }
    },
    updateExtraLocations() {
      const newExtraLocations = [];
      for (const measurement of this.measurementData) {
        if (measurement.measurement_location) {
          const location = measurement.measurement_location;
          location.name = measurement.profile;
          newExtraLocations.push(location);
        }
      }
      this.extraLocations = newExtraLocations;
    },
  },

  firestore: {
    locations: db.collection('masw_locations'),
  },
});
