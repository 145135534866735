import { render, staticRenderFns } from "./MeasurementCard.vue?vue&type=template&id=fd083888"
import script from "./MeasurementCard.vue?vue&type=script&lang=true"
export * from "./MeasurementCard.vue?vue&type=script&lang=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports