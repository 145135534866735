import firebase from 'firebase/app'
import 'firebase/firestore'

export const db = firebase
    .initializeApp({
        apiKey: 'AIzaSyDzkvS_jIXok9s08hIoU784dWRm0UP8xIg',
        authDomain: 'ssc-ice.firebaseapp.com',
        projectId: 'ssc-ice',
        storageBucket: 'ssc-ice.appspot.com',
        messagingSenderId: '961134069575',
        appId: '1:961134069575:web:d334f4a9414ec763616e62',
        measurementId: 'G-CTJTGYYK3C',
    })
    .firestore()
