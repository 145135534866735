<template>
    <v-card
        class="mx-auto"
        outlined
    >
        <v-card-text>
           <v-row no-gutters>
             <v-col lg="4" md="12" sm="12">
               <v-row>
                <v-col cols="5">
                      <v-list dense>
                        <v-subheader ><h2>{{ locationName }}</h2></v-subheader>
                        <v-subheader >{{ data.length }} {{ measurementText }}</v-subheader>
                        <v-divider></v-divider>
                        <v-list-item-group
                          v-model="selectedProfile"
                        >
                          <v-list-item v-for="(profile, index) in data" v-bind:key="index">
                            <v-list-item-content>
                              <v-list-item-title>{{ profile.profile }} - {{ profile.date }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                  </v-col>
                  <v-col cols="7">
                    <v-list class="mr-2" dense>
                        <v-subheader><h2>Selected Measurements </h2></v-subheader>
                          <p class="ma-3" v-if="!selectedMeasurementIds.length">To select click on a measurement to the left.</p>
                          <v-list-item v-for="id in selectedMeasurementIds" v-bind:key="id">
                              <v-list-item-action class="mr-2">
                                <v-checkbox
                                  v-model="checkedMeasurementIds"
                                  :color="calculateColor(id)"
                                  :value="id">
                                </v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title :style="getColorStyle(id)">{{ selectedMeasurementProfiles[id].profile }} - {{ selectedMeasurementProfiles[id].date }}</v-list-item-title>
                                <v-list-item-subtitle :style="getColorStyle(id)">{{ selectedMeasurementProfiles[id].location_name }}</v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-action class="ml-0">
                                <v-btn
                                  icon
                                  @click="removeSelectedMeasurement(id)"
                                >
                                  <v-icon >{{ icons.mdiClose }}</v-icon>
                                </v-btn>
                              </v-list-item-action>
                          </v-list-item>
                      </v-list>
                  </v-col>
               </v-row>
             </v-col>
             <v-col lg="8" md="12" sm="12">
               <v-row>
                <v-col cols="4">
                  <v-row>
                    <v-col>
                      <scatter-chart :chart-data-sets="rayleighChartDataSets" :chart-options="rayleighChartOptions"></scatter-chart>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0 pr-0" >
                      <v-checkbox class="my-0"
                        v-model="showConfidenceIntervalRayleigh"
                        label="Upper and lower boundary EDC"
                      ></v-checkbox>
                    </v-col>
                    <v-col class="py-0 pr-0">
                      <v-checkbox class="my-0"
                        v-model="showTheoreticalLineRayleigh"
                        label="Theoretical DC"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="4">
                  <v-row>
                  <v-col>
                    <scatter-chart :chart-data-sets="shearWaveVelocityStepFunctionChartDataSets" :chart-options="shearWaveVelocityStepFunctionChartOptions"></scatter-chart>
                  </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0 pr-0">
                      <v-checkbox class="my-0"
                        v-model="showConfidenceIntervalStepFunction"
                        label="10-th and 90-th percentiles"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="4">
                  <scatter-chart :chart-data-sets="shearWaveVelocityChartDataSets" :chart-options="shearWaveVelocityChartOptions"></scatter-chart>
                </v-col>
               </v-row>
             </v-col>
            </v-row>
        </v-card-text>


    </v-card>
</template>

<script src="https://raw.githubusercontent.com/google/palette.js/master/palette.js"></script>
<script lang>
  import ScatterChart from '../components/ScatterChart.vue';
  import {
    mdiClose,
  } from '@mdi/js'

  const graphColors = [
    "#ebac23",
    "#b80058",
    "#008cf9",
    "#006e00",
    "#00bbad",
    "#d163e6",
    "#b24502",
    "#ff9287",
    "#5954d6",
    "#00c6f8",
    "#878500",
    "#00a76c",
    "#bdbdbd",
  ];

  function getColor(index) {
    // Limitation is that we can only have 13 data sets to compare
    return graphColors[index];
  };
  export default {
    props: {
      data: {
        type: Array,
        required: true
      },
      dataIds: {
        type: Array,
        required: true
      },
      locationName: {
        type: String,
        required: true
      },
      loading: {
        type: Boolean,
        required: true
      }
    },
    components: {
      ScatterChart,
    },

    data: () => ({
      showConfidenceIntervalRayleigh: false,
      showTheoreticalLineRayleigh: false,
      showConfidenceIntervalStepFunction: false,
      icons: {
        mdiClose
      },
      selectedProfile: -1,
      selectedMeasurementProfiles: {},
      selectedMeasurementIds: [],
      checkedMeasurementIds: [],
      rayleighChartOptions: {
        title: {
          display: true,
          position: "top",
          fontSize: 18,
          text: "Dispersion curve",
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              reverse: true,
              stepSize: 10,
            },
            gridLines: {
              display: true
            },
            scaleLabel: {
              display: true,
              labelString: 'Wavelength [m]',
              fontSize: 14,
              fontStyle: 'bold'
            }
          }],
          xAxes: [ {
            ticks: {
              beginAtZero: false,
              stepSize: 50
            },
            gridLines: {
              display: true
            },
            scaleLabel: {
              display: true,
              labelString: 'Rayleigh wave velocity [m/s]',
              fontSize: 14,
              fontStyle: 'bold'
            }
          }]
        },
        legend: {
          display: false,
          labels: {
            usePointStyle: true,
            filter: function(item, chart) {
                // Logic to remove a particular legend item goes here
                return !item.text.includes('confidence');
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false
      },
      shearWaveVelocityChartOptions: {
        title: {
          display: true,
          position: "top",
          fontSize: 18,
          text: "Time-averaged shear wave velocity",
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              reverse: true,
              stepSize: 10,
            },
            gridLines: {
              display: true
            },
            scaleLabel: {
              display: true,
              labelString: 'Depth [m]',
              fontSize: 14,
              fontStyle: 'bold'
            }
          }],
          xAxes: [ {
            ticks: {
              beginAtZero: false,
              stepSize: 50
            },
            gridLines: {
              display: true
            },
            scaleLabel: {
              display: true,
              labelString: 'Time-averaged shear wave velocity [m/s]',
              fontSize: 14,
              fontStyle: 'bold'
            }
          }]
        },
        legend: {
          display: false,
          labels: {
            usePointStyle: true,
            filter: function(item, chart) {
                // Logic to remove a particular legend item goes here
                return !item.text.includes('confidence');
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false
      },
      shearWaveVelocityStepFunctionChartOptions: {
        title: {
          display: true,
          position: "top",
          fontSize: 18,
          text: "Median shear wave velocity",
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              reverse: true,
              stepSize: 10,
            },
            gridLines: {
              display: true
            },
            scaleLabel: {
              display: true,
              labelString: 'Depth [m]',
              fontSize: 14,
              fontStyle: 'bold'
            }
          }],
          xAxes: [ {
            ticks: {
              beginAtZero: false,
              stepSize: 50
            },
            gridLines: {
              display: true
            },
            scaleLabel: {
              display: true,
              labelString: 'Shear wave velocity [m/s]',
              fontSize: 14,
              fontStyle: 'bold'
            }
          }]
        },
        legend: {
          display: false,
          labels: {
            usePointStyle: true,
            filter: function(item, chart) {
                // Logic to remove a particular legend item goes here
                return !item.text.includes('confidence');
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }),
    watch: {
      selectedProfile: function() {
        if (this.selectedProfile === -1) {
          return;
        }
        const selectedId = this.dataIds[this.selectedProfile];
        if (selectedId !== undefined && !(selectedId in this.selectedMeasurementProfiles)) {
          // Only if the selectedId is not already in the selectedMeasurementProfiles map we add it.
          this.selectedMeasurementIds.push(this.dataIds[this.selectedProfile]);
          this.selectedMeasurementProfiles[selectedId] = this.data[this.selectedProfile];
          this.checkedMeasurementIds.push(selectedId);
        }
      },
      data: function() {
        this.selectedProfile = -1;
      }
    },

    methods: {
      removeSelectedMeasurement (id) {
        this.selectedMeasurementIds = this.selectedMeasurementIds.filter(item => item !== id);
        this.checkedMeasurementIds = this.checkedMeasurementIds.filter(item => item !== id);
        delete this.selectedMeasurementProfiles[id];
      },
      calculateColor(id) {
        let index = 0;
        for (let i = 0; i < this.selectedMeasurementIds.length; i++) {
          if (this.selectedMeasurementIds[i] == id) {
            index = i;
            break;
          }
        }
        return getColor(index);
      },
      getColorStyle(id) {
        if (this.checkedMeasurementIds.includes(id)) {
          return `color:${this.calculateColor(id)}`;
        } else {
          return '';
        }
      }
    },

    computed: {
      measurementText: function() {
        return this.data.length > 1 ? 'measurement profiles': 'measurement profile';
      },
      rayleighChartDataSets: function() {
        const dataSets = [];
        for (let i = 0; i < this.selectedMeasurementIds.length; i++) {
          // ##ConsistentColorsInGraphs
          // We loop over the selectedMeasurementIds instead of checkedMeasurementIds to keep the
          // colors consistent in case the user is temporarily disabling a measurement in the graph
          if (!(this.checkedMeasurementIds.includes(this.selectedMeasurementIds[i]))) {
            // This measurement is not checked. We skip it.
            continue;
          }
          const measurementProfile = this.selectedMeasurementProfiles[this.selectedMeasurementIds[i]];
          const dispersionCurve = measurementProfile.measured_dispersion_curve.map(point => ({ y: point.wavelength, x: point.v_r}) );
          const confidenceLower = measurementProfile.measured_dispersion_curve.map(point => ({ y: point.wavelength, x: point.v_r_low}) );
          const confidenceUpper = measurementProfile.measured_dispersion_curve.map(point => ({ y: point.wavelength, x: point.v_r_up}) );
          const theoreticalDispersionCurve = measurementProfile.theoretical_dispersion_curve.map(point => ({ y: point.wavelength, x: point.v_r}) );
          dataSets.push({
            label: `${measurementProfile.location_name} ${measurementProfile.profile}`,
            borderWidth: 2,
            pointBorderColor: getColor(i),
            borderColor: getColor(i),
            backgroundColor: 'transparent',
            data: dispersionCurve,
            showLine: true,
            pointRadius: 0,
            pointStyle: 'line',
            lineTension: 0,
          })
          if (this.showConfidenceIntervalRayleigh) {
            dataSets.push({
              label: 'measured confidence lower',
              borderWidth: 2,
              borderDash: [5, 5],
              borderColor: getColor(i),
              pointBorderColor: getColor(i),
              backgroundColor: 'transparent',
              data: confidenceUpper,
              showLine: true,
              pointRadius: 0,
              pointStyle: 'line',
              lineTension: 0,
            })
            dataSets.push({
              label: 'measured confidence upper',
              borderWidth: 2,
              borderDash: [5, 5],
              borderColor: getColor(i),
              pointBorderColor: getColor(i),
              backgroundColor: 'transparent',
              data: confidenceLower,
              showLine: true,
              pointRadius: 0,
              pointStyle: 'line',
              lineTension: 0,
            })
          }
          if (this.showTheoreticalLineRayleigh) {
            dataSets.push({
              label: 'theoretical',
              borderWidth: 2,
              borderColor: '#000000',
              pointBorderColor: '#000000',
              backgroundColor: 'transparent',
              data: theoreticalDispersionCurve,
              showLine: true,
              pointRadius: 0,
              pointStyle: 'line',
              lineTension: 0,
            })
          }
        }
        return dataSets;
      },
      shearWaveVelocityStepFunctionChartDataSets: function() {
        const stepFunctionData = [];
        for (let i = 0; i < this.selectedMeasurementIds.length; i++) {
          // #ConsistentColorsInGraphs
          if (!(this.checkedMeasurementIds.includes(this.selectedMeasurementIds[i]))) {
            continue;
          }
          const measurementProfile = this.selectedMeasurementProfiles[this.selectedMeasurementIds[i]];
          const stepFunctionPoints = [];
          let previousYStep = 0.0;
          let previousXStep = 0.0;
          for (let j = 0; j < measurementProfile.shear_wave_velocity_with_confidence_interval.length; j++) {
            const currentDataPoint = measurementProfile.shear_wave_velocity_with_confidence_interval[j];
            stepFunctionPoints.push({ y: previousYStep, x: currentDataPoint.v_s_median});
            stepFunctionPoints.push({ y: currentDataPoint.z_median, x: currentDataPoint.v_s_median});

            if (this.showConfidenceIntervalStepFunction) {
              // The Confidence point which uses x
              const confidenceYMidPoint = {y: previousYStep + (currentDataPoint.z_median - previousYStep)/2, x: currentDataPoint.v_s_median};
              const confidenceYLow = {y: confidenceYMidPoint.y, x: currentDataPoint.v_s_10};
              const confidenceYUp = {y: confidenceYMidPoint.y, x: currentDataPoint.v_s_90};
              const confidenceIntervalVSPoints = [confidenceYLow, confidenceYMidPoint, confidenceYUp];
              stepFunctionData.push({
                label: 'confidence',
                borderWidth: 2,
                pointBorderColor: getColor(i),
                borderColor: getColor(i),
                backgroundColor: 'transparent',
                data: confidenceIntervalVSPoints,
                showLine: true,
                pointRadius: 3,
                lineTension: 0,
                pointStyle: 'line',
              })
              if (previousXStep !== 0.0) {
                const previousPoint = measurementProfile.shear_wave_velocity_with_confidence_interval[j - 1];
                const confidenceXMidPoint = { y: previousPoint.z_median, x: previousXStep + (currentDataPoint.v_s_median - previousXStep) / 2 };
                const confidenceXLow = {y: previousPoint.z_10, x: confidenceXMidPoint.x};
                const confidenceXUp = {y: previousPoint.z_90, x: confidenceXMidPoint.x};
                const confidenceIntervalZPoints = [confidenceXLow, confidenceXMidPoint, confidenceXUp];
                stepFunctionData.push({
                  label: 'confidence',
                  borderWidth: 2,
                  pointBorderColor: getColor(i),
                  borderColor: getColor(i),
                  backgroundColor: 'transparent',
                  data: confidenceIntervalZPoints,
                  showLine: true,
                  pointRadius: 3,
                  lineTension: 0,
                  pointStyle: 'line',
                })
              }
            }

            previousXStep = currentDataPoint.v_s_median;
            previousYStep = currentDataPoint.z_median;
          }
          stepFunctionData.push({
            label: `${measurementProfile.location_name} ${measurementProfile.profile}`,
            borderWidth: 2,
            pointBorderColor: getColor(i),
            borderColor: getColor(i),
            backgroundColor: 'transparent',
            data: stepFunctionPoints,
            showLine: true,
            pointRadius: 0,
            lineTension: 0,
            pointStyle: 'line',
          })
        } 
        return stepFunctionData;
      },
      shearWaveVelocityChartDataSets: function() {
        const shearWaveData = []
        for (let i = 0; i < this.selectedMeasurementIds.length; i++) {
          // #ConsistentColorsInGraphs
          if (!(this.checkedMeasurementIds.includes(this.selectedMeasurementIds[i]))) {
            continue;
          }
          const measurementProfile = this.selectedMeasurementProfiles[this.selectedMeasurementIds[i]];
          const dataPoints = measurementProfile.shear_wave_velocity.map(point => ({ y: point.z, x: point.v_sz}) );
          shearWaveData.push({
            label: `${measurementProfile.location_name} ${measurementProfile.profile}`,
            borderWidth: 2,
            pointBorderColor: getColor(i),
            borderColor: getColor(i),
            backgroundColor: 'transparent',
            data: dataPoints,
            showLine: true,
            pointRadius: 0,
            lineTension: 0,
            pointStyle: 'line',
          });
        }
        return shearWaveData;
      }
    }
  }

</script>
