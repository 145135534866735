<script>
  import { Scatter } from 'vue-chartjs'
  export default {
    extends: Scatter,
    props: {
      chartDataSets: {
        type: Array,
        required: true
      },
      chartOptions: {
        type: Object,
        required: true
      },
    },

    methods: {
      renderScatterChart: function() {
        this.renderChart({
          datasets: this.chartDataSets
          }, this.chartOptions
        );
      }
    },
    mounted () {
      this.renderScatterChart();
    },
    watch: {
      chartDataSets: function() {
        //this.renderChart(this.data, this.options);
        this.renderScatterChart();
      }
    }
  }
</script>